.loanConsultantsTable {
  tr {
    background-color: white;
  }
}

.card {
  padding: 20px 20px 0 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
