@import 'styles/variables.scss';

.bodyOpen {
  overflow-y: hidden;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* background: rgba(243, 246, 255, 0.3); */
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  z-index: $z-index-modal;
  padding: 20px;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.modal {
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0;
  outline: none;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;
  position: relative;
  border: none;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  min-height: 250px;
  overflow: hidden;
  padding: 0 20px 20px 20px;
  max-width: 900px;
  width: 100%;
  z-index: $z-index-modal;
}

.modalAfterOpen {
  opacity: 1;
  max-height: 750px;
  height: 90%;
}

.modalBeforeClose {
  opacity: 0;
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  margin-left: -20px;
  margin-right: -20px;
}

.modalCloseButton {
  border: none;
  cursor: pointer;
  font-size: 30px;
  height: 40px;
  width: 50px;
  line-height: 40px;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBody {
  overflow: auto;
  height: 95%;
}
