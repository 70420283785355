p {
  margin-bottom: 1em;
}

ul,
ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

h1 {
  font-weight: 500;
  font-size: 2em;
  word-break: break-word;
  @media only screen and (max-width: 768px) {
    font-size: 1.6em;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.link {
  font-weight: 400;
  color: $primary-color;
  background-color: transparent;
  padding: 0.375em 0;
  font-size: 1em;
  text-decoration: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.card {
  position: relative;
  padding: 20px;
  border: 1px solid #f2f2f2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  background-color: white;
}

input {
  border: none;
  width: 100%;
  // border: none;
  padding-bottom: 10px;
  outline: none;
  font-size: 16px;
  padding-left: 0;
  background: transparent;
  font-family: $font-family;
  border-radius: 0;
  box-shadow: none;
}

.default-input {
  border-bottom: 1px solid #adadad;
  padding-top: 8px;
}

.default-input:focus {
  // border-bottom: 1px solid #f8b01b;
  border-bottom: 1px solid $primary-color;
  outline: none;
}

label {
  font-size: 15px;
  color: #999999;
  display: block;
  cursor: auto;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.muted {
  color: #666;
}

.field-wrapper {
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 5px;
  width: 100%;
  label {
    position: absolute;
    transition: all 0.2s linear;
    left: 0px;
    top: 30px;
    pointer-events: none;
  }
  .label-when-focused-input,
  input:focus + label {
    top: 5px;
    font-size: 13px;
    color: $primary-color;
    // color: #f8b01b;
  }
}

.error-input {
  border-bottom: 1px solid #dc3545;
}

.disabled-input {
  cursor: not-allowed;
}

.error-text {
  font-size: 13px;
  margin-top: 5px;
  color: #dc3545;
  position: absolute;
}

.error-wrapper {
  padding: 20px;
  p {
    font-size: 15px;
    position: static;
  }
}

.datepicker-wrapper {
  margin-top: 10px;
  border-bottom: 1px solid #adadad;
}
.react-datepicker-wrapper {
  display: block;
}

// rc-checkbox

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: $primary-color;
  background-color: $primary-color;
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: $primary-color;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: $primary-color;
}

.rc-pagination {
  display: flex;
  justify-content: center;
}

.rc-pagination-item-active {
  background-color: #2db7f5;
  border-color: #2db7f5;
}

.rc-pagination-item {
  border-radius: 6px;
}

.rc-pagination-item a {
  text-decoration: none;
  color: #666666;
}

.rc-pagination-item-active a {
  color: white;
}

.pagination-item-active:hover a {
  color: white;
  li:focus {
    outline: none;
  }
}

.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
  margin-top: -27px;
  visibility: visible;
}

.rc-pagination-prev,
.rc-pagination-next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-sidebar-backdrop {
  z-index: 9;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}

.rc-pagination-prev,
.rc-pagination-next {
  display: flex;
  align-items: center;
  justify-content: center;
}
