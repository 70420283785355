.noItemsView {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    font-size: 20px;
    margin-right: 10px;
  }
}
.cardBody {
  white-space: pre-line;
}