.striped {
  background-color: white !important;
  font-weight: bold;
}
.filter {
  margin: 10px 0;
}
.header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}