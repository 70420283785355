.messageContainer {
  padding: 0 30px;
  max-height: 650px;
  height: 90%;
  overflow-y: auto;
}
.filter {
  padding: 10px 20px 0 20px;
  margin-bottom: 0px;
}
