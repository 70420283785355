.messageTable {
  table {
    table-layout: fixed;
    overflow: scroll;
  }
  tr {
    background-color: #f5f7f7;
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 55%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 15%;
      }
    }
  }
}

.noItemsView {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    font-size: 20px;
    margin-right: 10px;
  }
}
