body,
html {
  height: 100%;
  margin: 0;
  background-color: $background-color;
  font-family: $font-family;
}

.container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 1400px) {
    width: 1220px;
  }
}

.container-fluid {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

$content-padding-top: $navbar-height + 20px;
$content-padding-bottom: $footer-height;
.content {
  margin: 0 auto;
  padding-top: $content-padding-top;
  padding-bottom: $content-padding-bottom;
  position: relative;
}

$content-min-height: calc(100vh - #{$content-padding-top} - #{$content-padding-bottom} - 20px);
.page-content-min-height {
  min-height: $content-min-height;
}

.container-narrow {
  max-width: 700px;
}

.primary-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background: linear-gradient($primary-color, $primary-color);
}

#root {
  position: relative;
  font-family: $font-family;
  min-height: 100%;
  background-color: $background-color;
  &,
  * {
    box-sizing: border-box;
  }
}
