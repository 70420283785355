.loanInput {
  padding: 6px 8px !important;
}
.headerWrapper {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.interestRate {
  input {
    min-width: 100px;
  }
}
