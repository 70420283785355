.pdfButton {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
