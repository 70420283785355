/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/montserrat-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ //  url('./fonts/montserrat-v14-latin-regular.woff') format('woff'),  Modern Browsers
      url('./fonts/montserrat-v14-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
  //  url('./fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
