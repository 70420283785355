@import 'styles/variables.scss';

.filter {
  margin: 10px 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0 !important;
  padding-right: 0  !important;
}
.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  button:first-child {
    margin: 5px;
  }
}
